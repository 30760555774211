.div-center {
    display: table;
    margin: 0 auto;
}

.div-center img {
    width: 10rem;
}

.buttons-group {
    display: flex;
}

.buttons-group button:first-child {
    margin-right: 8px;
}

a, div, img, p, button {
    -moz-user-select: none;
     -ms-user-select: none;
         user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
}
.Persik {
  display: block;
  width: 90%;
  max-width: 1200px;
  margin: 20px auto;
}

.Persik {
  display: block;
  height: 450px;
}

